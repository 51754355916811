import * as React from "react";
import dayjs from "dayjs";

const Education = ({ education }) => {
  return (
    <section id="education" className="hover:bg-gray-100">
      <h3>{education.institution}</h3>
      <div className="md:flex md:justify-between">
        <span className="block md:inline">
          {education.degree} - {education.area}
        </span>
        <span className="block md:inline space-x-1">
          <time dateime={dayjs(education.startDate).format("YYYY")}>
            {dayjs(education.startDate).format("YYYY")}
          </time>
          <span>-</span>
          <time dateime={dayjs(education.endDate).format("YYYY")}>
            {dayjs(education.endDate).format("YYYY")}
          </time>
        </span>
      </div>
      <ul>
        {education.highlights.map((hl, index) => {
          return <li key={index}>{hl}</li>;
        })}
      </ul>
    </section>
  );
};

export default Education;
