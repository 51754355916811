import * as React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
// import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

// const phoneUtil = PhoneNumberUtil.getInstance();
const Basic = ({ basic }) => {
  // const phone = phoneUtil.parse(basic.phone, "AU");
  return (
    <address id="basic" className="md:flex md:justify-between">
      <OutboundLink className="block md:inline" href={basic.url}>
        {basic.url}
      </OutboundLink>
      {/* <OutboundLink href={`mailto:${basic.email}`}>{basic.email}</OutboundLink>
      <OutboundLink
        className="block md:inline"
        href={phoneUtil.format(phone, PhoneNumberFormat.RFC3966)}
      >
        {phoneUtil.format(phone, PhoneNumberFormat.NATIONAL)}
      </OutboundLink> */}
      <span className="block md:inline">{basic.location.city}, Australia</span>
    </address>
  );
};

export default Basic;
