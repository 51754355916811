import * as React from "react";
const skillsColorMap = {
  "Ruby on Rails": "red",
  Ruby: "red",
  RSpec: "red",
  React: "blue",
  Javascript: "yellow",
  Webpack: "blue",
  Hotwire: "yello",
  "Gastby.js": "purple",
  TailwindCSS: "green",
  Jamstack: "pink",
  PostgreSQL: "blue",
  Python: "indigo",
  ZPL: "green",
  Git: "red",
  Github: "gray",
  Terraform: "indigo",
  Buildkite: "green",
  JIRA: "blue",
  Confluence: "blue",
  Snowflake: "indigo",
  HTML: "red",
  CSS: "blue",
  Rudderstack: "purple"
};

function bgColor(skill) {
  const color = skillsColorMap[skill];
  switch (color) {
    case "green":
      return "bg-green-50";
    case "red":
      return "bg-red-50";
    case "yellow":
      return "bg-yellow-50";
    case "pink":
      return "bg-pink-50";
    case "gray":
      return "bg-gray-50";
    case "purple":
      return "bg-purple-50";
    case "indigo":
      return "bg-indigo-50";
    case "blue":
      return "bg-blue-50";
    default:
      return "bg-green-50";
  }
}

function hoverColor(skill) {
  const color = skillsColorMap[skill];
  switch (color) {
    case "green":
      return "hover:bg-green-100";
    case "red":
      return "hover:bg-red-100";
    case "yellow":
      return "hover:bg-yellow-100";
    case "pink":
      return "hover:bg-pink-100";
    case "gray":
      return "hover:bg-gray-100";
    case "purple":
      return "hover:bg-purple-100";
    case "indigo":
      return "hover:bg-indigo-100";
    case "blue":
      return "hover:bg-blue-100";
    default:
      return "hover:bg-green-100";
  }
}


function textColor(skill) {
  const color = skillsColorMap[skill];
  switch (color) {
    case "green":
      return "text-green-600";
    case "red":
      return "text-red-600";
    case "yellow":
      return "text-yellow-600";
    case "pink":
      return "text-pink-600";
    case "gray":
      return "text-gray-600";
    case "purple":
      return "text-purple-600";
    case "indigo":
      return "text-indigo-600";
    case "blue":
      return "text-blue-600";
    default:
      return "text-green-600";
  }
}
const Skills = ({ skills }) => {
  return (
    <section id="skills">
      <h2>🛠 Skills</h2>
      <ul>
        {skills.map((skill, index) => {
          return (
            <li key={index} className="flex flex-wrap">
              {skill.keywords.map((keyword, index) => {
                return (
                  <span key={index}
                    className={`${bgColor(keyword)} ${textColor(keyword)} ${hoverColor(keyword)} cursor-default px-2 py-1 text-xs rounded-full mr-2 mb-4 shadow-md font-semibold hover:shadow-lg`}
                  >
                    {keyword}
                  </span>
                );
              })}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Skills;
