import * as React from "react";
import ResumeData from "../../content/resume.json";
import Basic from "../templates/basic";
import Skills from "../templates/skills";
import Highlights from "../templates/highlights";
import Education from "../templates/education";
import WorkSection from "../templates/workSection";
import { Helmet } from "react-helmet";

const ResumePage = () => {
  return (
    <main className="max-w-screen-md antialiased mx-auto prose prose-sm prose-purple md:prose md:prose-purple">
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Zhong Zheng</title>
        <link rel="canonical" href="https://zhong.dev" />
      </Helmet>
      <article className="px-4">
        <header className="md:text-center">
          <h1>{ResumeData.basic.name}</h1>
          <h2>{ResumeData.basic.label}</h2>
        </header>
        <Basic basic={ResumeData.basic} />
        <Skills skills={ResumeData.skills} />
        <Highlights highlights={ResumeData.highlights} />
        <WorkSection work={ResumeData.work} />
        <section>
          <h2>👨‍🎓 Education</h2>
          {ResumeData.education.map((ed) => {
            return <Education education={ed} key={ed.institution} />;
          })}
        </section>
      </article>
    </main>
  );
};

export default ResumePage;
