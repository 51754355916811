import * as React from "react";
import dayjs from "dayjs";

const Work = ({ work }) => {
  return (
    <li className="hover:bg-gray-100 pb-4">
      <h3>{work.name}</h3>
      <div className="md:flex md:justify-between items-center">
        <span className="block md:inline text-lg font-semibold">
          {work.position}
        </span>
        <span className="block md:inline space-x-1 text-gray-600 text-sm">
          <time dateime={dayjs(work.startDate).format("YYYY-MM")}>
            {dayjs(work.startDate).format("MMM, YYYY")}
          </time>
          <span>-</span>
          {work.endDate ? (
            <time dateime={dayjs(work.endDate).format("YYYY-MM")}>
              {dayjs(work.endDate).format("MMM, YYYY")}
            </time>
          ) : (
            <span>Present</span>
          )}
        </span>
      </div>
      {/* <p>{work.description}</p> */}
      <ul>
        {work.highlights.map((hl, index) => {
          return <li key={index}>{hl}</li>;
        })}
      </ul>
    </li>
  );
};

export default Work;
