import * as React from "react";
import Work from "../templates/work";

const WorkSection = ({ work }) => {
  const works = work.map((job) => {
    return <Work work={job} key={job.name} />;
  });
  return (
    <section id="work">
      <h2>💻 Experiences</h2>
      <ul className="">{works}</ul>
    </section>
  );
};

export default WorkSection;
