import * as React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Highlights = ({ highlights }) => {
  return (
    <section id="highlights">
      <h2>✨ Highlights</h2>
      <ul>
        {highlights.map((hl, index) => {
          return (
            <li key={index}>
              <OutboundLink href={hl.url}>{hl.description}</OutboundLink>
              {hl.alter_url && (
                <OutboundLink href={hl.alter_url} className="ml-4">
                  {hl.alter_url_label}
                </OutboundLink>
              )}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Highlights;
